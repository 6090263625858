<template>
  <ValidationObserver
    ref="bulkCommunicationModal"
  >
    <b-modal
      :id="isBulk ? 'bulk-communication-modal' : 'single-communication-modal'"
      :ref="isBulk ? 'bulk-communication-modal' : 'single-communication-modal'"
      :title="isBulk ? 'Bulk Communicate' : 'Send Communication'"
      centered
      no-close-on-backdrop
      no-enforce-focus
      cancel-variant="outline-secondary"
      size="lg"
      @hidden="initCommunicationData"
      @show="preparePlaceholdersData"
    >
      <b-overlay
        id="overlay-background"
        :show="isLoading"
        variant="white"
        opacity="1"
        rounded="sm"
      >
        <b-row>
          <b-col
            md="6"
          >
            <b-form-group
              label="Communication Type"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Communication Type"
                rules="required"
              >
                <v-select
                  v-model="communication.communicationType"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="communicationTypeOptions"
                  :clearable="false"
                  label="name"
                  :selectable="type => type.active"
                  :placeholder="fieldTypeStatus"
                  :class="{'is-invalid': errors.length > 0 }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="!isSmsType"
            md="6"
          >
            <b-form-group
              label="Email Template"
              class="mb-2"
            >
              <v-select
                v-model="communication.emailTemplate"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="allEmailTemplatesList"
                :clearable="false"
                label="name"
                value="id"
                :placeholder="fieldTemplateStatus"
                @input="selectedEmailTemplate"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="isSmsType"
            md="6"
          >
            <b-form-group
              label="SMS Template"
              class="mb-2"
            >
              <v-select
                v-model="communication.smsTemplate"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="allSmsTemplatesList"
                :clearable="false"
                label="name"
                value="id"
                :placeholder="fieldTemplateStatus"
                @input="selectedSmsTemplate"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <template v-if="!isSmsType">
          <b-row>
            <b-col md="6">
              <b-form-group
                label="From Name"
                class="mb-2"
              >
                <b-form-input
                  v-model="communication.fromName"
                  placeholder="Name"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="From Email"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="email"
                >
                  <b-form-input
                    v-model="communication.fromEmail"
                    placeholder="Email"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group
                label="Subject"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="subject"
                  rules="required"
                >
                  <b-form-input
                    v-model="communication.subject"
                    placeholder="Subject"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </template>

        <b-row>
          <b-col
            v-if="!isSmsType"
            cols="12"
          >
            <b-form-group
              label="Body"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="body"
                rules="required"
              >
                <editor
                  id="content"
                  ref="myEditor"
                  v-model="communication.content"
                  :api-key="tinyAPI"
                  :init="tinyOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="isSmsType"
            cols="12"
          >
            <b-form-group
              label="Body"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="body"
                rules="required"
              >
                <b-form-textarea
                  id="sms-content"
                  ref="smsContent"
                  v-model="communication.content"
                  placeholder="Enter SMS"
                  rows="5"
                  @blur="setCursorPosition"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <AvailablePlaceholder
              v-if="placeholdersOptions"
              :default-placeholders="placeholdersOptions"
              @insertValueToEditor="insertValueToEditor"
            />
          </b-col>

          <b-col
            v-if="withExecute"
            cols="6"
          >
            <b-form-group
              label="Field with available communication type"
            >
              <validation-provider
                #default="{ errors }"
                name="destination field"
                rules="required"
              >
                <v-select
                  v-model="communication.targetField"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="destinationTypeOptions"
                  :reduce="option => option.value"
                  :clearable="false"
                  placeholder="Please select a field"
                  :disabled="!destinationTypeOptions.length"
                  :class="{'is-invalid': errors.length > 0 }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>
      </b-overlay>

      <template #modal-footer>
        <span
          v-if="isBulk"
          class="mr-1 text-muted"
        > {{ countContacts }} Contacts
        </span>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          :disabled="isLoading"
          @click="closeModal"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          class="float-right"
          :disabled="isLoading"
          @click="handleForm"
        >
          Send
        </b-button>
      </template>
    </b-modal>
  </ValidationObserver>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import {
  BModal,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BOverlay,
  BButton,
  BFormTextarea,
} from 'bootstrap-vue'

import { tinyOptions } from '@core/mixins/tinyOptions'
import Editor from '@tinymce/tinymce-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import AvailablePlaceholder from '@/views/components/available-placeholder/AvailablePlaceholder.vue'
import useCommunicationSetting from '@/views/admin/communication/useСommunicationSetting'
import useBulkCommunicate from '@/views/components/bulk-communicate-modal/useBulkCommunicate'
import store from '@/store'
import { permissionSubjects } from '@/libs/acl/constants'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BOverlay,
    BButton,
    BFormTextarea,
    Editor,
    vSelect,
    AvailablePlaceholder,
  },
  directives: {
    Ripple,
  },
  mixins: [tinyOptions],
  props: {
    markedElements: {
      type: Array,
      required: true,
    },
    fieldsOptions: {
      type: [Array, Object],
      default: () => {},
    },
    executeData: {
      type: Object,
      default: () => {},
    },
    allElementsChecked: {
      type: Boolean,
      default: () => true,
    },
    totalElementsCount: {
      type: Number,
      default: () => 0,
    },
    withExecute: {
      type: Boolean,
      default: () => false,
    },
    filterName: {
      type: String,
      default: null,
    },
    isBulk: {
      type: Boolean,
      default: true,
    },
    groupId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      communication: {},
      communicationTypeOptions: [],
      allEmailTemplatesList: [],
      allSmsTemplatesList: [],
      placeholdersOptions: [],
      destinationTypeOptions: [],
      fieldTypeStatus: 'Loading...',
      fieldTemplateStatus: 'Loading...',
      isLoading: false,
      lastCursorPosition: 0,
      required,
      email,
    }
  },
  setup() {
    const {
      fetchCommunicationTypes,
      fetchAllEmailTemplatesList,
      fetchAllSmsTemplatesList,
    } = useCommunicationSetting()

    const {
      bulkCommunicate,
      bulkCommunicateFromReport,
    } = useBulkCommunicate()

    return {
      fetchCommunicationTypes,
      fetchAllEmailTemplatesList,
      fetchAllSmsTemplatesList,
      bulkCommunicate,
      bulkCommunicateFromReport,
    }
  },
  computed: {
    countContacts() {
      if (this.allElementsChecked) {
        return this.totalElementsCount
      }
      return this.markedElements.length
    },
    isSmsType() {
      return this.communication.communicationType && this.communication.communicationType?.name === 'sms'
    },
    programId() {
      return store.getters['verticalMenu/getDefaultProgram']
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'communication.communicationType': function () {
      this.communication.content = ''
    },
  },
  async created() {
    this.tinyOptions.height = 300

    await this.initCommunicationData()

    await this.setCommunicationTypeOptions()

    await this.fetchAllEmailTemplatesList().then(response => {
      this.allEmailTemplatesList = response
      this.fieldTemplateStatus = 'Select'
    })

    await this.fetchAllSmsTemplatesList().then(response => {
      this.allSmsTemplatesList = response
      this.fieldTemplateStatus = 'Select'
    })
  },
  methods: {
    async setCommunicationTypeOptions() {
      await this.fetchCommunicationTypes().then(response => {
        const typeOptions = response.map(item => {
          if (this.isBulk) {
            if (item.name === 'sms') {
              // eslint-disable-next-line no-param-reassign
              item.active = this.$can('send', permissionSubjects.BulkSMSCommunication)
            }
            if (item.name === 'email') {
            // eslint-disable-next-line no-param-reassign
              item.active = this.$can('send', permissionSubjects.BulkEmailCommunication)
            }
          } else {
            if (item.name === 'sms') {
              // eslint-disable-next-line no-param-reassign
              item.active = this.$can('send', permissionSubjects.SingleSMSCommunication)
            }
            if (item.name === 'email') {
            // eslint-disable-next-line no-param-reassign
              item.active = this.$can('send', permissionSubjects.SingleEmailCommunication)
            }
          }
          return item
        })
        this.communicationTypeOptions = typeOptions
        this.fieldTypeStatus = 'Select'
      })
    },
    closeModal() {
      const ref = this.isBulk ? 'bulk-communication-modal' : 'single-communication-modal'
      this.$refs[ref].hide()
    },
    preparePlaceholdersData() {
      if (this.withExecute) {
        this.destinationTypeOptions = this.fieldsOptions
        this.fieldsOptions.forEach(row => {
          const placeholder = {
            type: 'fixed',
            value: `{{_${row.value}}}`,
            label: row.value,
          }
          this.placeholdersOptions.push(placeholder)
        })
      }
    },
    async initCommunicationData() {
      this.communication = {
        content: null,
        communicationType: null,
        emailTemplate: null,
        smsTemplate: null,
        fromName: null,
        fromEmail: null,
        subject: null,
        targetField: null,
      }
      this.placeholdersOptions = []
      this.destinationTypeOptions = []
    },
    handleForm() {
      return new Promise((resolve, reject) => {
        this.$refs.bulkCommunicationModal.validate().then(success => {
          if (success) {
            resolve(true)
            this.isLoading = true
            this.sendMassCommunication()
          } else {
            reject()
          }
        })
      })
    },
    async sendMassCommunication() {
      const queryParams = {
        communicationData: {
          message: this.communication.content,
          communication_type_id: this.communication.communicationType.id,
          from_name: this.communication.fromName,
          from_email: this.communication.fromEmail,
          subject: this.communication.subject,
          targetField: this.communication.targetField,
          program_id: this.programId,
        },
      }

      if (this.withExecute) {
        queryParams.executeData = this.executeData
        queryParams.executeData.program_id = this.programId
        queryParams.allElementsChecked = this.allElementsChecked
        if (!this.allElementsChecked) {
          queryParams.recipientsData = this.markedElements
        }
      }

      if (this.allElementsChecked && this.groupId) {
        queryParams.communicationData.group_id = this.groupId
      } else if (this.filterName) {
        queryParams.communicationData.filters = {
          [this.filterName]: this.markedElements,
        }
      }

      this.isLoading = true
      if (this.withExecute) {
        await this.bulkCommunicateFromReport(queryParams)
          .then(response => {
            if (response.status === 200) {
              this.$emit('successfullySend')
            }
          })
          .finally(() => {
            this.isLoading = false
          })
      } else {
        await this.bulkCommunicate(queryParams)
          .then(response => {
            if (response.status === 200) {
              this.$emit('successfullySend')
            }
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    selectedEmailTemplate() {
      this.communication.content = this.communication.emailTemplate.body
      this.communication.subject = this.communication.emailTemplate.subject
    },
    selectedSmsTemplate() {
      this.communication.content = this.communication.smsTemplate.body
    },
    insertValueToEditor(val) {
      if (this.isSmsType) {
        const result = this.communication.content.slice(0, this.lastCursorPosition) + val + this.communication.content.slice(this.lastCursorPosition)
        this.communication.content = result
      } else {
        window.tinyMCE.activeEditor.execCommand('mceInsertContent', false, val)
      }
    },
    setCursorPosition(event) {
      this.lastCursorPosition = event.target.selectionEnd
    },
  },
}
</script>

<style lang="scss">
  .is-invalid {
    .vs__dropdown-toggle {
      border: 1px solid #ea5455;
    }
  }
</style>
