import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useBulkAddGroups() {
  // Use toast
  const toast = useToast()

  const fetchSessions = queryParams => axios
    .get('auth/sessions', {
      params: queryParams,
    })
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Sessions',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const fetchGroups = queryParams => axios
    .get('auth/groups', {
      params: queryParams,
    })
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Groups',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const fetchUsers = queryParams => axios
    .get('auth/applications', {
      params: queryParams,
    })
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Users',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const addUsersToGroup = queryParams => axios
    .post(`/auth/groups/${queryParams.group_id}/add-users`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Users were successfully assined',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response
    })
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error assigning Users to Group',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const addApplicationsToGroup = queryParams => axios
    .post(`/auth/groups/${queryParams.group_id}/add-applications`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Users were successfully assined',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response
    })
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error assigning Users to Group',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchSessions,
    fetchGroups,
    fetchUsers,
    addUsersToGroup,
    addApplicationsToGroup,
  }
}
